import {css} from "@emotion/react";
import {responsibleGamingHeaderHeight, sm, linkBlue} from "atg-ui/css/variables.styles";

export const logos = css`
    display: flex;
    margin-bottom: 2px;
    padding-right: 0;

    @media ${sm} {
        margin-bottom: 0;
    }
`;

export const caption = css`
    font-size: 12px;
    color: #2a3e50;
    opacity: 0.7;
    margin-bottom: 2px;
    margin-left: 8px;
`;

export const responsibleGaming = css`
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #dee1e6;
    width: 100%;
    height: ${responsibleGamingHeaderHeight}px;
    position: fixed;
    top: 0;
    left: 0;
    /* make sure ATGCHECK is always on the top */
    z-index: 2000;
    font-size: 12px;
    justify-content: center;
    padding: 8px;
    box-shadow: inset 0px -11px 8px -10px rgba(0, 0, 0, 0.04);

    @media ${sm} {
        justify-content: space-between;
    }
`;

// this is used to override responsible gaming header styling when the verticals top nav is active
export const responsibleGamingWithVerticalsTopNav = css`
    background-color: #f5f5f5; // TODO: Investigate: Fine to change?
`;

// this is used to override responsible gaming header styling when the verticals top nav is displayed
export const responsibleGamingWithoutShadow = css`
    box-shadow: none; // TODO: Investigate: Fine to change?
`;

export const responsibleGamingATGCheck = css`
    display: flex;
    justify-content: center;
`;

export const atgCheckLink = css`
    margin-left: 8px;
    color: ${linkBlue};
`;

export const atgCheckLogo = css`
    height: 12px;
    margin-top: 2px;
`;
