import {some} from "lodash/fp";
import * as React from "react";
import * as Redux from "react-redux";
import queryString from "query-string";
import {useLocation, matchPath, Outlet, useMatch} from "react-router-dom";
import root from "window-or-global";
import {useGlobalDispatch} from "@atg-global-shared/redux-store-hooks";
import {isAppWebview} from "@atg-shared/system";
import {ComponentLoader} from "@atg-shared/lazy";
import {isDevice} from "@atg/utils/device";
import {jumpToId} from "@atg/utils/focus";
import {
    CasinoLoader,
    SportsbookLoader,
    HorseLoader,
    GlobalLoader,
    PlayLoader,
    TillsammansLoader,
    AmlLoader,
    PaymentLoader,
    NavbarLoader,
    RgLoader,
} from "@atg-frame-shared/bundle-loader";
import Features, {
    lastVertical,
    newVerticalNavbar,
    navbarMicroFE,
    rgMicroFe,
} from "@atg-shared/client-features";
import {VideoFramePlaceholder} from "@atg-horse-shared/videoframe";
import {VerticalsSelectors, useVerticals} from "@atg-global-shared/verticals";
import {SystemAlerts} from "@atg-global-shared/alerts";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import * as UserActions from "@atg-global-shared/user/userActions";
import {useIsBig9Retail} from "@atg-sport/big9-data-access-retail";
import * as PushActions from "@atg-frame-shared/push-saga/domain/pushActions";
import {initLoginTime} from "@atg-global-shared/login-time-data-access";
// This should be removed when trustly deposit is an iFrame instead of redirect
import {DepositActions} from "@atg-payment-shared/deposit-domain";
import {ResponsibleGamingHeader} from "@atg-responsible-gambling-shared/header-feature";
import {ErrorBoundary} from "@atg-shared/error-boundary";
import {CookieConsent} from "@atg-frame/cookie-consent";
import {LazyFooter} from "@atg-content-shared/footer";
import {Box} from "@atg-ui/toolkit";
// @ts-expect-error Flow
import {StickyController} from "@atg-shared/sticky";
import {ButtonBase} from "atg-ui-components";
import {MainMenuSelectors} from "atg-main-menu";
import SideMenuWrapper from "atg-main-menu/components/SideMenu";
import CurityLogout from "atg-member-logout/components/CurityLogout";
import "atg-ui/css/Scrollbars.global.styles";
import SideMenuPlaceholder from "atg-main-menu/components/SideMenuPlaceholder";
import {a11y as a11yMixins} from "atg-ui-components/mixins";
import {MainFrame, LazyModalFrame} from "atg-frame-components";
import BasicHeader from "atg-main-menu/components/BasicHeader";
import * as ModalSelectors from "atg-modals/modalSelectors";
import Toasts from "atg-ui-toast/components/Toasts";
import * as frameActions from "../../domain/frameActions";
import LazyABTestOverlay from "../LazyABTestOverlay";
import LazySessionTimer from "../LazySessionTimer";
import LazyCampaignStartPage from "../CampaignStartPage";
import {LazyLimboDebugger} from "../internal/LimboDebugger";
import {LazyScopeDebugger} from "../internal/ScopeDebugger";
import {useFeatureToggleRoute} from "../../hooks/useFeatureToggleRoute";
import type MainContent from "./MainContent";
import "atg-ui/css/Scrollbars.global.styles";
import * as styles from "./BaseFrame.styles";

function GlobalHeader() {
    return <GlobalLoader componentName="Header" loading={<BasicHeader />} />;
}

function NavigationApp() {
    return <NavbarLoader componentName="App" loading={null} />;
}

function CasinoSideMenu() {
    return <CasinoLoader componentName="SideMenu" loading={<SideMenuPlaceholder />} />;
}

function HorseSideMenu() {
    return <HorseLoader componentName="SideMenu" loading={<SideMenuPlaceholder />} />;
}

function PlayMiniPlayer() {
    return !isDevice() ? <PlayLoader componentName="VideoFrame" loading={null} /> : null;
}

function SportsbookSideMenu() {
    return (
        <SportsbookLoader componentName="SideMenu" loading={<SideMenuPlaceholder />} />
    );
}
function TillsammansSideMenu() {
    return (
        <TillsammansLoader componentName="SideMenu" loading={<SideMenuPlaceholder />} />
    );
}

function KycQuestionnaireModal() {
    return <AmlLoader componentName="Modal" loading={null} />;
}

function DepositModal() {
    const {header} = Redux.useSelector(ModalSelectors.getDepositModalHeader);
    return (
        <PaymentLoader
            componentName="DepositModal"
            componentProps={header}
            loading={null}
        />
    );
}

function LazyMainContent(props: React.ComponentProps<typeof MainContent>) {
    return (
        <ComponentLoader
            loader={import(/* webpackChunkName: "MainContent" */ "./MainContent")}
            placeholder={null}
            {...props}
        />
    );
}

function LazyNavbar() {
    return (
        <ComponentLoader
            loader={import(
                /* webpackChunkName: "Navbar" */ "@atg-global/main-navbar/src/components/Navbar"
            )}
            placeholder={null}
        />
    );
}

function DepositBudgetUpdateModal() {
    return <RgLoader componentName="DepositBudgetUpdateModal" loading={null} />;
}
function RealityCheckModal() {
    return <RgLoader componentName="RealityCheckModal" loading={null} />;
}
function UserReachedTimeLimitModal() {
    return <RgLoader componentName="UserReachedTimeLimitModal" loading={null} />;
}
function LogoutUserFifteenMinutesModal() {
    return <RgLoader componentName="LogoutUserFifteenMinutesModal" loading={null} />;
}
function LogoutUserOnTimeLimitReachedModal() {
    return <RgLoader componentName="LogoutUserOnTimeLimitReachedModal" loading={null} />;
}
function UserGamblingSummaryModal() {
    return <RgLoader componentName="UserGamblingSummaryModal" loading={null} />;
}

function VideoFrame() {
    const isVideoFrameExpanded =
        // TODO: Don't access horse store directly, find a better way to do this
        // eslint-disable-next-line atg/no-direct-store-access
        window._horseStore?.getState()?.video?.videoFrame?.videoFrameExpanded;
    // TODO: Don't access horse store directly, find a better way to do this
    // eslint-disable-next-line atg/no-direct-store-access
    const isLivePlaying = window._horseStore?.getState()?.video?.players?.live?.playing;
    const isSportVertical = Redux.useSelector(VerticalsSelectors.isSport);
    const isCasinoVertical = Redux.useSelector(VerticalsSelectors.isCasino);
    const location = useLocation();
    const match = useMatch(location.pathname);

    const [showVideo, setShowVideo] = React.useState(false);
    React.useEffect(() => {
        const isRattaPage = Boolean(matchPath("/ratta/*", location.pathname));

        if (isRattaPage) {
            // on /ratta page always hide video frame
            setShowVideo(false);
            return;
        }

        let shouldShow = true;

        shouldShow =
            Boolean(match) &&
            !(
                isDevice() &&
                !isLivePlaying &&
                !isVideoFrameExpanded &&
                (isCasinoVertical || isSportVertical)
            );

        setShowVideo(shouldShow);
    }, [
        isCasinoVertical,
        isLivePlaying,
        isSportVertical,
        isVideoFrameExpanded,
        location.pathname,
        match,
    ]);

    return showVideo ? (
        <HorseLoader componentName="VideoFrame" loading={<VideoFramePlaceholder />} />
    ) : null;
}

const MAIN_CONTAINER_ID = "main-container";

/**
 * This is an array of all paths that should have **NOT** render any chrome (menu, header, etc.).
 * The reason we put `BaseFrame` close to the root of the render tree and then have this logic
 * **inside** of it, instead of just wrapping the parts of the site that want it with `<BaseFrame>`, the
 * is because we don't want `BaseFrame` to remount when you (for example) switch betweenn Casino and
 * Horse, since that would cause the video player to unmount and stop playing.
 */
const ROUTES_WITHOUT_BASEFRAME = [
    "/preview",
    "/konto/betalning",
    "/reduceratpdf",
    "/app/*",
    "/video/*",
    "/play/e/*",
    "/sport/big9-retail",
    "/discovery/*",
];

function BaseFrame() {
    const dispatch = useGlobalDispatch();
    const isLastVerticalEnabled = Features.isEnabled(lastVertical);
    const showKycQuestionModal = Redux.useSelector(
        ModalSelectors.shouldShowKycQuestionnaireModal,
    );
    const showDepositModal = Redux.useSelector(ModalSelectors.isInDepositFlow);
    const modals = Redux.useSelector(ModalSelectors.selectModals);
    const location = useLocation();
    const [canSeeCampaign, setCanSeeCampaign] = React.useState<boolean>(false);
    const isBig9Retail = useIsBig9Retail();
    const hideBaseFrame = some(
        (routeMatch) => matchPath(routeMatch, location.pathname),
        ROUTES_WITHOUT_BASEFRAME,
    );

    const newNavEnabled = Features.isEnabled(newVerticalNavbar);
    const navigationMicroFEEnabled = Features.isEnabled(navbarMicroFE);

    const user = Redux.useSelector(UserSelectors.getUser);
    const isOverlayMenuOpen: boolean = Redux.useSelector(
        MainMenuSelectors.isOverlayMenuOpen,
    );
    const isStickySideMenuOpen: boolean = Redux.useSelector(
        MainMenuSelectors.isStickySideMenuOpen,
    );
    const isSportVertical = Redux.useSelector(VerticalsSelectors.isSport);
    const isCasinoVertical = Redux.useSelector(VerticalsSelectors.isCasino);
    const isTillsammansVertical = Redux.useSelector(VerticalsSelectors.isTillsammans);

    const anySideMenuIsOpen = isOverlayMenuOpen || isStickySideMenuOpen;

    const handleVisibilityChange = React.useCallback(() => {
        if (root.document.visibilityState === "visible") {
            dispatch(frameActions.frameTabBecameActive());
        }
    }, [dispatch]);

    useFeatureToggleRoute();

    const vertical = useVerticals();

    // Set a lastVertical cookie with expiration when the session ends
    React.useEffect(() => {
        if (isLastVerticalEnabled) {
            root.document.cookie = `lastVertical=${vertical}; path=/`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Always set a lastVertical cookie when the vertical changes
    React.useEffect(() => {
        if (isLastVerticalEnabled) {
            localStorage.setItem("lastVertical", vertical);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vertical]);

    // fetch data needed for the header to render
    React.useEffect(() => {
        // for retail big9 we only want to start the push
        if (isBig9Retail) {
            dispatch(PushActions.init());
            return () => undefined;
        }

        dispatch(UserActions.loadLocalUser());
        dispatch(UserActions.fetchUserIfAuthorized());
        // This should be removed when trustly deposit is an iFrame instead of redirect
        dispatch(DepositActions.loadDepositState());

        if (!isAppWebview) {
            dispatch(PushActions.init());
            dispatch(initLoginTime());
        }

        root.document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            root.document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, handleVisibilityChange]);

    React.useEffect(() => {
        if (document.body) {
            if (isOverlayMenuOpen) {
                document.body.classList.add("scroll-killer");
            } else {
                document.body.classList.remove("scroll-killer");
            }
        }
    }, [isOverlayMenuOpen]);

    if (hideBaseFrame) return <Outlet />;

    const query = queryString.parse(location.search);
    const isPopup = query.newWindow === "true";
    if (isPopup)
        return (
            <MainFrame>
                <Outlet />
            </MainFrame>
        );

    let SideMenu = HorseSideMenu;
    if (isSportVertical) SideMenu = SportsbookSideMenu;
    if (isCasinoVertical) SideMenu = CasinoSideMenu;
    if (isTillsammansVertical) SideMenu = TillsammansSideMenu;

    return (
        <div css={styles.frame(canSeeCampaign)}>
            <nav css={styles.a11yContainer}>
                <ButtonBase tabIndex={-1} aria-hidden="true" />

                {anySideMenuIsOpen && (
                    <ButtonBase
                        role="link"
                        onClick={() => jumpToId("sidemenu_anch")}
                        style={a11yMixins.hiddenIndex}
                    >
                        Hoppa till meny
                    </ButtonBase>
                )}

                <ButtonBase
                    role="link"
                    onClick={() => jumpToId("header_anch")}
                    style={a11yMixins.hiddenIndex}
                >
                    Hoppa till toppnavigation
                </ButtonBase>

                <ButtonBase
                    role="link"
                    onClick={() => jumpToId("main")}
                    style={a11yMixins.hiddenIndex}
                >
                    Hoppa till huvudinnehåll
                </ButtonBase>
            </nav>
            <StickyController containerId={MAIN_CONTAINER_ID}>
                {newNavEnabled ? (
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        {navigationMicroFEEnabled ? <NavigationApp /> : <LazyNavbar />}
                        <LazyMainContent
                            containerId={MAIN_CONTAINER_ID}
                            setCanSeeCampaign={setCanSeeCampaign}
                        />
                    </Box>
                ) : (
                    <>
                        <ResponsibleGamingHeader />
                        <div id="sidemenu_anch">
                            <SideMenuWrapper>
                                <SideMenu />
                            </SideMenuWrapper>
                        </div>
                        <div
                            id={MAIN_CONTAINER_ID}
                            css={styles.mainContainer(
                                isStickySideMenuOpen,
                                !canSeeCampaign,
                            )}
                        >
                            <LazyLimboDebugger />
                            <LazyScopeDebugger />
                            <Toasts />
                            <SystemAlerts />

                            <div id="header_anch">
                                <GlobalHeader />
                            </div>

                            <LazyCampaignStartPage
                                handleCampaignClose={() => setCanSeeCampaign(false)}
                                handleCampaignDisplay={() => setCanSeeCampaign(true)}
                            />
                            <VideoFrame />
                            {Features.isEnabled("loseMiniPlayer") && <PlayMiniPlayer />}
                            <div>
                                <MainFrame className="main-wrapper" user={user}>
                                    <Outlet />
                                </MainFrame>
                            </div>
                            <LazyFooter />
                        </div>
                    </>
                )}
            </StickyController>
            {showKycQuestionModal && <KycQuestionnaireModal />}
            {showDepositModal && <DepositModal />}
            {Features.isEnabled(rgMicroFe) && (
                /* TODO RG: Remove this FF condition when rg microFe is live */
                <>
                    {modals.showDepositLimitModal && <DepositBudgetUpdateModal />}
                    <RealityCheckModal />
                    {modals.showRgsAtgCheckModal && <UserReachedTimeLimitModal />}
                    {modals.showFifteenMinutesLeftModal && (
                        <LogoutUserFifteenMinutesModal />
                    )}
                    {modals.showLoginTimeReachedLogoutModal && (
                        <LogoutUserOnTimeLimitReachedModal />
                    )}
                    {modals.showUserGamblingSummaryModal && <UserGamblingSummaryModal />}
                </>
            )}
            <LazyModalFrame />
            <CurityLogout />
            <LazyABTestOverlay />
            <LazySessionTimer />
            <ErrorBoundary name="CookieConsentBanner">
                <CookieConsent />
            </ErrorBoundary>
        </div>
    );
}

export default BaseFrame;
