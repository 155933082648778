import {createSelector} from "reselect";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {now, parseDateTimestamp} from "@atg-shared/datetime";
import type {RealityCheckState} from "@atg-responsible-gambling-shared/reality-check-types";

dayjs.extend(relativeTime);

type State = {
    realityCheck: RealityCheckState;
};

export const getRealityCheckState = (state: State): RealityCheckState =>
    state && state.realityCheck;

export const getSessionDuration: (state: State) => string = createSelector(
    getRealityCheckState,
    (state: RealityCheckState): string =>
        state?.startTime
            ? parseDateTimestamp(state.startTime).fromNow(true)
            : now().fromNow(true),
);

export const showModal: (state: State) => boolean = createSelector(
    getRealityCheckState,
    (state) => state && state.showModal,
);

export const noInterruptMode: (state: State) => boolean = createSelector(
    getRealityCheckState,
    (state) => state && state.noInterruptMode,
);
