import type {Reducer} from "redux";
import {combineReducers} from "redux";
import {
    type GamblingResult,
    type LatestSuccessfulLoginTime,
    type NarBlocked,
    type UserGamblingSummaryState,
    constants,
} from "@atg-responsible-gambling-shared/user-gambling-summary-types";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {Action} from "../actionTypes";

const {GAMBLING_RESULT_SUCCESS, LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS, NAR_BLOCK_SUCCESS} =
    constants;

const gamblingResult = (state: GamblingResult = null, action: Action): GamblingResult => {
    switch (action.type) {
        case GAMBLING_RESULT_SUCCESS:
            return action.payload;
        case LOGOUT_FINISHED:
            return null;
        default:
            return state;
    }
};

const latestSuccessfulLoginTime = (
    state: LatestSuccessfulLoginTime = null,
    action: Action,
): LatestSuccessfulLoginTime => {
    if (action.type === LATEST_SUCCESFUL_LOGIN_TIME_SUCCESS) {
        if (!action.payload) return state;
        return action.payload;
    }
    return state;
};

const narBlocked = (state: NarBlocked = false, action: Action): NarBlocked => {
    switch (action.type) {
        case NAR_BLOCK_SUCCESS:
            return action.payload;
        case LOGOUT_FINISHED:
            return null;
        default:
            return state;
    }
};

export const userGamblingSummary: Reducer<UserGamblingSummaryState, Action> =
    combineReducers({
        gamblingResult,
        latestSuccessfulLoginTime,
        narBlocked,
    });
