import type {Dayjs} from "dayjs";
import {fetchAuthorized} from "@atg-shared/auth";
import atgRequest, {addAuthHeaders} from "@atg-shared/fetch";
import {
    USER_SERVICE_URL,
    UNAUTHORIZED_LOGIN_SERVICE_URL,
    TEAM_SERVICE_API_URL,
    CUSTOMER_TRANSACTIONS_URL,
} from "@atg-shared/service-url";
import type {
    GamblingResult,
    LatestSuccessfulLoginTime,
} from "@atg-responsible-gambling-shared/user-gambling-summary-types";

type LoginResponse = {
    result: string;
    timestamp: Dayjs;
};

export const fetchGamblingResult = (): Promise<GamblingResult> =>
    fetchAuthorized<GamblingResult>(`${CUSTOMER_TRANSACTIONS_URL}/result-now`, {
        method: "GET",
        headers: addAuthHeaders({}),
    }).then((response) => response.data);

export const fetchLatestSuccessfulLoginTime = (): Promise<LatestSuccessfulLoginTime> =>
    atgRequest<LoginResponse>(`${UNAUTHORIZED_LOGIN_SERVICE_URL}?filter=SUCCEEDED`, {
        method: "GET",
        headers: addAuthHeaders({}),
    }).then((response) => response.data.timestamp);

export const fetchNarBlocked = (): Promise<boolean> => // If blocked true(200), otherwise false(204 or any other)
    atgRequest(`${USER_SERVICE_URL}/nar-blocked`, {
        method: "GET",
        headers: addAuthHeaders({}),
    })
        .then((response) => response.meta.code === 200)
        .catch(() => false);

export const fetchNarBlockedTillsammans = (): Promise<boolean> => // If blocked true(200), otherwise false(204 or any other)
    atgRequest(`${TEAM_SERVICE_API_URL}/v1/user/nar-blocked`, {
        method: "GET",
        headers: addAuthHeaders({}),
    })
        .then((response) => response.meta.code === 200)
        .catch(() => false);
