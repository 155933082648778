import {take, takeEvery, takeLatest, select, put, call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {AUTH_CHECK_RESPONSE, AUTH_SET_ROLES} from "@atg-shared/auth/domain/authActions";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import {LOGIN_FINISHED, LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {broadcastAction} from "atg-store-addons";
import * as LimitsActions from "./limitsActions";
import {APP_SPORT_USER_READY} from "./limitsActions";

export function* fetchDepositLimitsSaga(): SagaIterator {
    // Dispatch the action to fetch deposit limits
    yield put(LimitsActions.fetchDepositLimits());

    // Wait for the action that indicates deposit limits have been received
    const receiveDepositLimitsAction = yield take(
        LimitsActions.RECEIVE_GET_DEPOSIT_LIMITS,
    );

    // Broadcast the received deposit limits action
    yield put(broadcastAction(receiveDepositLimitsAction));

    // Check if the action has onSuccess or onFailure callbacks
    const {onSuccess, onFailure} = receiveDepositLimitsAction;

    // Call the appropriate callback based on the success or failure of the API call
    if (receiveDepositLimitsAction.error && onFailure) {
        onFailure();
    } else if (onSuccess) {
        onSuccess();
    }
}

export function* resetLimitsStatus() {
    yield put(LimitsActions.resetStatus());
}

export default function* limitsSaga(): SagaIterator {
    yield take([
        AUTH_CHECK_RESPONSE,
        LOGIN_FINISHED,
        AUTH_SET_ROLES,
        APP_SPORT_USER_READY,
    ]);
    if (yield select(AuthSelectors.isNormalLogin)) {
        yield call(fetchDepositLimitsSaga);
    }

    yield takeEvery(
        [
            LOGIN_FINISHED,
            DepositActionConstants.DEPOSIT_SUCCESS,
            DepositActionConstants.DEPOSIT_FAILURE,
        ],
        fetchDepositLimitsSaga,
    );

    yield takeLatest([LOGOUT_FINISHED], resetLimitsStatus);
}
