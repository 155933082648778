import root from "window-or-global";
import * as React from "react";
import {memoize, noop} from "lodash";
import {isWeb} from "@atg-shared/system";
import log, {serializeError} from "@atg-shared/log";
import {pureFetch} from "@atg-shared/fetch";
import * as Storage from "@atg-shared/storage";
import type {
    ExperienceConfig,
    ExperienceOptions,
    ExperienceReturnValue,
    ExperiencePayload,
    ExperienceResponse,
    UseAbTestWithRenderPropsType,
    UseAbTestWithRenderPropsWithTimeoutType,
} from "@atg-shared/personalization-types";
import {getQubitExperienceApiUrl} from "./config";
import {
    CONTEXT_ID_COOKIE,
    CONTEXT_ID_KEY,
    getItemFromStorage,
    getQubitContextId,
    prefixKey,
    env,
    getMappedExperience,
} from "./personalizationUtil";
import * as personalizationStore from "./personalizationStore";

const getApiRoot = () => getQubitExperienceApiUrl(env, isWeb);

const trackVariation = ({
    callback,
    id: experienceId,
    variation: variationId,
    payload,
}: ExperiencePayload) => {
    const event = {
        event: "qubit.experience",
        qubitExperimentId: experienceId,
        qubitVariationMasterId: variationId,
    };

    const disableTracking = payload?.disableTracking ?? false;
    if (!disableTracking) {
        // trackEvent in atg-analytics enforces a camel-case name, but to have old and native
        // experiences tracked side by side, we want to keep the event name as "qubit.experience"
        root.dataLayer.push(event);
    }

    pureFetch(callback, {method: "post"}).catch((error) => {
        log.error(`personalizationHooks::useNativeABTest: callback to Qubit failed`, {
            error: serializeError(error),
            experienceId,
        });
    });
};

/**
 * Fetch experience payload from Qubit
 */
export const getExperience = memoize(async (experienceId: number) => {
    const contextId = getQubitContextId();
    let url = `${getApiRoot()}?experienceIds=${experienceId}`;

    if (contextId !== undefined) {
        url += `&contextId=${contextId}`;
    }

    const variationOverride = JSON.parse(
        getItemFromStorage(`experience-${experienceId}`) ?? "null",
    );
    const ignoreSegments = getItemFromStorage("ignore-segments") === "true";

    if (variationOverride) {
        url += `&variation=${variationOverride}&preview`;
    }

    if (ignoreSegments) {
        url += `&ignoreSegments`;
    }

    const response = await pureFetch<ExperienceResponse>(url);

    if (contextId === undefined) {
        if (isWeb) {
            // In case a new user and the __qubitTracker cookie has not yet been set,
            // qubit returns a context id we can use
            const newContextId = response.data.contextId;
            const expiresAt = new Date();
            expiresAt.setFullYear(expiresAt.getFullYear() + 1);

            document.cookie = `${CONTEXT_ID_COOKIE}=${newContextId}; expires=${expiresAt.toUTCString()}; path=/`;
        } else {
            const newContextId = response.data.contextId;
            Storage.setItem(prefixKey(CONTEXT_ID_KEY), newContextId);
        }
    }

    if (response.data.experiencePayloads.length > 0) {
        trackVariation(response.data.experiencePayloads[0]);
    }

    return response;
});

function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        promise.then(resolve).catch(reject);
        setTimeout(() => {
            reject(new Error("Timeout reached for experience"));
        }, timeout);
    });
}

async function fetchExperience(experienceId: number, timeout?: number) {
    const getExperiencePromise = getExperience(experienceId);

    const {data} = await (timeout
        ? timeoutPromise(getExperiencePromise, timeout)
        : getExperiencePromise);

    const [experience] = data.experiencePayloads;

    return experience;
}

/**
 * A function that corresponds to the useNativeABTest hook. This is useful
 * outside of React, for instance in sagas or similar.
 *
 * @param options.cache controls if the fetch experience call should use cache (optional, default: true)
 * @param options.timeout number of milliseconds until the fetch experience call should timeout (optional, default: undefined = no timeout)
 * @param options.shouldThrow controls if an error should be thrown to the caller if the fetch experience call fails (optional: default: false)
 * }
 *
 * By default this function is memoized and will never make more than one call to the Qubit API for
 * any given experience. `options.cache` can be set to `false` to disable this behavior.
 */
export async function runNativeABTest(
    config: ExperienceConfig,
    options?: {
        cache?: boolean;
        timeout?: number;
        shouldThrow?: boolean;
    },
): Promise<ExperienceReturnValue> {
    const {cache = true, timeout} = options ?? {};
    const {experienceId} = config[env];

    // In some situations we want to throw away any cached/memoized result (for example when
    // using the AB test overlay to change the current variation). The lodash memoized
    // function exposes the cache like this, so we can delete any potentially existing cache
    // before calling the function.
    if (!cache) getExperience.cache.delete(experienceId);

    const experienceDataFromStore = personalizationStore.getExperience(experienceId);

    if (!cache || !experienceDataFromStore) {
        try {
            const experience = await fetchExperience(experienceId, timeout);

            if (!experience) {
                return {
                    variation: 0,
                };
            }

            personalizationStore.setExperience(experience);
            return getMappedExperience(experience, config);
        } catch (error: unknown) {
            if (options?.shouldThrow) {
                throw error;
            }

            log.error(
                `personalizationUtils:runNativeABTest: failed to fetch experience from Qubit`,
                {
                    error: serializeError(error),
                    experienceId,
                },
            );

            return {
                // default to the first variation (typically the control)
                variation: 0,
            };
        }
    } else {
        return getMappedExperience(experienceDataFromStore, config);
    }
}

function useExperienceData(experienceId: number, enabled: boolean) {
    const [experienceData, setExperienceData] = React.useState(
        personalizationStore.getExperience(experienceId),
    );

    React.useEffect(() => {
        let unsubscribe: () => void = noop;
        if (enabled) {
            unsubscribe = personalizationStore.subscribe(
                experienceId,
                (updatedExperience) => setExperienceData(updatedExperience),
            );
        }

        return unsubscribe;
    }, [enabled, experienceId]);

    return experienceData;
}

/**
 * React hook for using native experiences. Returns the current variation and eventual payload
 * described in fields.json
 *
 * Note: this hook will always return `{variation: 0}` on the first render. If you want to
 * wait until Qubit has returned the correct variation before rendering anything, @see {useNativeABTestWithTimeout}
 * Note: the variation is index based, so instead of getting the variation id, you will get a number,
 * where `0` is the control. Therefore, the order of variation IDs in the config array is important.
 *
 * In general the A/B test tracking will start as soon as this hook is called. If you want to track
 * conditionally (e.g. only for small screens), use `options.enabled` to control when the test gets
 * triggered.
 * (Reminder: As per [Rules of Hooks](https://reactjs.org/docs/hooks-rules.html) hooks themselves
 * should never be called conditionally)
 *
 *
 * Example usage:
 *
 * ```js
 * const {variation, fields} = useNativeAbTest({
 *   dev: {experienceId: 200722, variations: [1237861, 1246234]},
 *   prod: {experienceId: 207232, variations: [1237123, 1234123]}
 * });
 * ```
 *
 * @see {useNativeABTestWithTimeout}
 *
 * @param {ExperienceConfig} config
 * @param {ExperienceOptions} overrideOptions
 * @param {boolean} overrideOptions.enabled - the experience (and tracking) will not run when this is false
 * @returns {ExperienceReturnValue}
 */
export function useNativeABTest(
    config: ExperienceConfig,
    options?: ExperienceOptions,
): ExperienceReturnValue {
    const {enabled = true} = options ?? {};
    const {experienceId} = config[env];
    const experienceData = useExperienceData(experienceId, enabled);

    const mappedExperience = React.useMemo(
        () =>
            experienceData ? getMappedExperience(experienceData, config) : {variation: 0},
        [experienceData, config],
    );

    React.useEffect(() => {
        if (enabled && !personalizationStore.getExperience(experienceId)) {
            (async () => {
                try {
                    await runNativeABTest(config, {shouldThrow: true});
                } catch (error: unknown) {
                    log.error(
                        `personalizationHooks:useNativeABTest: failed to fetch experience from Qubit`,
                        {
                            error: serializeError(error),
                            experienceId,
                        },
                    );
                }
            })();
        }
        // make sure we do not accidentaly put the hook in an endless loop if the config object
        // is passed in as `useNativeABTest({...})` for some reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    if (!enabled) {
        return {variation: 0};
    }

    return mappedExperience;
}

/**
 * Timeout based version of above hook. Will return undefined until Qubit has
 * decided which variation to show, OR when the specified timeout has passed.
 *
 * Note: second argument is optional, if no timeout is specified, will default to 5000ms
 *
 * In general the A/B test tracking will start as soon as this hook is called. If you want to track
 * conditionally (e.g. only for small screens), use `options.enabled` to control when the test gets
 * triggered.
 * (Reminder: As per [Rules of Hooks](https://reactjs.org/docs/hooks-rules.html) hooks themselves
 * should never be called conditionally)
 *
 * Example usage:
 *
 * ```js
 * const experience = useNativeABTestWithTimeout({
 *   dev: { experienceId: 200722, variations: [1237861, 1246234]},
 *   prod: { experienceId: 207232, variations: [1237123, 1234123]}
 * }, {timeout: 5000});
 *
 * if (!experience) {
 *   // still waiting, show spinner
 * } else {
 *   // got result OR timeout
 *   const { variation, fields } = experience
 * }
 * ```
 *
 * @see {useNativeABTest}
 *
 * @param {ExperienceConfig} config
 * @param {ExperienceOptions} overrideOptions
 * @param {boolean} overrideOptions.enabled - the experience (and tracking) will not run when this is false
 * @param {number} overrideOptions.timeout - optional, timeout defaults to 5000ms
 */
export function useNativeABTestWithTimeout(
    config: ExperienceConfig,
    options?: ExperienceOptions & {
        timeout?: number;
    },
): ExperienceReturnValue | null | undefined {
    const {enabled = true, timeout = 5000} = options ?? {};

    const {experienceId} = config[env];
    const experienceData = useExperienceData(experienceId, enabled);

    const [mappedExperience, setMappedExperience] = React.useState(
        experienceData ? getMappedExperience(experienceData, config) : undefined,
    );

    React.useEffect(() => {
        if (experienceData) {
            setMappedExperience(getMappedExperience(experienceData, config));
        }
    }, [experienceData, config]);

    React.useEffect(() => {
        if (enabled && !personalizationStore.getExperience(experienceId)) {
            (async () => {
                try {
                    const experience = await runNativeABTest(config, {
                        shouldThrow: true,
                        timeout,
                    });
                    setMappedExperience(experience);
                } catch (error: unknown) {
                    log.error(
                        `personalizationHooks:useNativeABTestWithTimeout: failed to fetch experience from Qubit, falling back to variation 0`,
                        {
                            error: serializeError(error),
                            experienceId,
                        },
                    );
                    setMappedExperience({variation: 0});
                }
            })();
        }
        // make sure we do not accidentaly put the hook in an endless loop if the config object
        // is passed in as `useNativeABTestWithTimeout({...})` for some reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    if (!enabled) {
        return {variation: 0};
    }

    return mappedExperience;
}

// This wrapper is required because new hooks useNativeABTest || useNativeABTestWithTimeout are not compatible with class component
export const UseAbTestWithRenderProps = (props: UseAbTestWithRenderPropsType) => {
    const experience = useNativeABTest(props.config, props.options);
    return props.children(experience);
};
export const UseAbTestWithRenderPropsWithTimeout = (
    props: UseAbTestWithRenderPropsWithTimeoutType,
) => {
    const experience = useNativeABTestWithTimeout(props.config, props.options);
    return props.children(experience);
};
